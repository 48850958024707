import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get, isEmpty, isEqual } from 'lodash';

import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import RichText from '../../shared/RichText';
import Visible from '../../shared/Visible';

import VmHeroImage from './viinimaa/Image';
import FofHeroImage from './folkofolk/Image';
import HeroVideo from './HeroVideo';
import PhotoCredit from '../../shared/PhotoCredit';

import '../Content';

const styles = theme => ({
  root: {
    display: 'block',
    marginBottom: '40px',
    '@media print': {
      maxHeight: '320px',
    },
  },
  container: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      maxWidth: ({ disableMaxWidth }) => {
        if (disableMaxWidth) {
          return '100%';
        }
        return null;
      },
    },
  },
  imageTitle: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    transform: 'translate(-0%, -50%)',
  },
  textOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  contentContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '40px',
      paddingRight: '40px',
    },
  },
  description: {
    padding: '20px',
    maxHeight: '500px',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      padding: '40px',
      width: '450px',
    },
  },
});

function HeroImage({ classes, className, pageData, children, disableGutters, disableDescription, ...otherProps }) {
  const inspectorProps = useContentfulInspectorMode();
  const description = get(pageData, 'heroImage.description');
  const heroImageData = get(pageData, 'heroImage');
  const heroVideo = get(pageData, 'heroImage.video');
  // const desctiptionStyle = get(pageData, 'heroImage.descriptionStyle.title');
  const photoCred = get(heroImageData, 'photoCredit');
  const photoCredColor = get(heroImageData, 'photoCreditColor', null);
  let heroImage = <VmHeroImage pageData={pageData} {...otherProps} />;
  if (isEqual(siteId, siteIds.Folkofolk)) {
    heroImage = <FofHeroImage pageData={pageData} {...otherProps} />;
  }

  if (!heroImage) {
    return null;
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...inspectorProps({
        entryId: get(pageData, 'contentful_id'),
        fieldId: 'heroImage',
        locale: 'en-US',
      })}>
      <Container disableGutters={disableGutters} className={classes.container}>
        {isEmpty(heroVideo) ? heroImage : <HeroVideo data={heroImageData} />}
        <Visible hidden={isEmpty(get(description, 'raw')) || !isEmpty(heroVideo) || disableDescription}>
          <div className={classes.content}>
            <Container className={classes.contentContainer}>
              <div className={classes.description}>
                <RichText textData={description} />
              </div>
            </Container>
          </div>
        </Visible>
        <Visible hidden={isEmpty(children)}>
          <div className={classes.textOverlay}> {children}</div>
        </Visible>
        <PhotoCredit photoCredit={photoCred} color={photoCredColor} />
      </Container>
    </div>
  );
}

HeroImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disableMaxWidth: PropTypes.bool,
  children: PropTypes.node,
  disableGutters: PropTypes.bool,
  disableDescription: PropTypes.bool,
};

HeroImage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  disableMaxWidth: false,
  children: null,
  disableGutters: false,
  disableDescription: false,
};

export default withStyles(styles)(HeroImage);

export const query = graphql`
  fragment HeroImageFragment on ContentfulHeroImageModule {
    ...ContentHeroImageModuleFragment
  }
`;
