import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Container, Typography, withStyles } from '@material-ui/core';
import SiteNotification from '../../SiteNotifications/viinimaa';

const styles = theme => ({
  root: {
    display: 'block',
    paddingTop: '120px',
  },
  wrapper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  title: {
    textTransform: 'uppercase',
    margin: theme.spacing(1, 0),
  },
  line: {
    width: '32px',
    height: '3px',
    backgroundColor: '#d68d00',
  },
});

function Title({ classes, className, pageData }) {
  let title = get(pageData, 'title');
  const description = get(pageData, 'mainTheme.siteNotification.description');
  if (isEmpty(title)) {
    title = get(pageData, 'name');
  }

  return (
    <div className={clsx(classes.root, className)}>
      {description && <SiteNotification pageData={pageData} />}

      {title && (
        <Container maxWidth="md">
          <div className={classes.wrapper}>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            <div className={classes.line} />
          </div>
        </Container>
      )}
    </div>
  );
}

Title.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Title.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(Title);
