import React from 'react';

import isEqual from 'lodash/isEqual';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaShortDescription from './viinimaa';

function ShortDescription(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaShortDescription {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaShortDescription {...props} />;
  }
  if (isEqual(siteId, siteIds.Koskenkorva)) {
    return <ViinimaaShortDescription {...props} />;
  }
  if (brandSite) {
    return <ViinimaaShortDescription {...props} />;
  }

  return null;
}

export default ShortDescription;
