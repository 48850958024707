import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withStyles, Typography, Container } from '@material-ui/core';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { contentPageMaxWidth } from '../../../../constants';

const styles = theme => ({
  root: {
    display: 'block',
  },
  shortDescription: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0),
    },
  },
});

function ShortDescription({ classes, className, pageData }) {
  const inspectorProps = useContentfulInspectorMode();
  const shortDescription = get(pageData, 'shortDescription');

  if (isEmpty(shortDescription)) {
    return null;
  }
  return (
    <Container maxWidth={contentPageMaxWidth} className={clsx(classes.root, className)}>
      <Typography
        className={classes.shortDescription}
        {...inspectorProps({
          entryId: get(pageData, 'contentful_id'),
          fieldId: 'shortDescription',
        })}>
        {shortDescription}
      </Typography>
    </Container>
  );
}

ShortDescription.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ShortDescription.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ShortDescription);
